import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { AiDataVoiceSample } from 'stores/aiPlatform';
import { AudioPlayerCell } from './AudioPlayerCell';

export const columns: MRT_ColumnDef<AiDataVoiceSample>[] = [
  {
    accessorKey: 'audioUri',
    header: i18n.t('dataGenerationPage.sampleTable.voice.audioHeader'),
    size: 350,
    Cell: AudioPlayerCell,
    grow: 0,
  },
  {
    accessorKey: 'transcript',
    header: i18n.t('dataGenerationPage.sampleTable.voice.transcriptHeader'),
    grow: 1,
  },
];
