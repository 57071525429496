// TODO: All types here will eventually be imported as a package

import { FlowId, Version } from '@flow/flow-backend-types';

export * from './graphql.types';

export interface GraphQLResponse<T> {
  data: T;
  errors?: any[];
}

export enum DataGenerationStatus {
  success = 'success',
  failure = 'failure',
}

export type DataGenExecutionId = string;

export enum StartTrainModel {
  both = 'both',
  nlp = 'nlp',
  asr = 'asr',
}

export enum TrainStatus {
  success = 'success',
  failure = 'failure',
}

export type TrainExecutionId = string;

export type TextGenSettings = {
  asrAugmentationPortion?: number;
  punctuationAugmentationPortion?: number;
  structuredTextGen: boolean;
  numSamplesPerTemplate?: number;
};

export type VoiceGenSettings = {
  sliceRatio?: number;
  noiseRatio?: number;
};

export type DataGenSettings = {
  language: string;
  numSamples: number;
  textGenSettings?: TextGenSettings;
  voiceGenSettings?: VoiceGenSettings;
  customerConfigSource?: string;
  customerConfigPromptsSource?: string;
  predefinedConfigSource?: string;
  externalTextFile?: string;
  sampleExecutionId?: string;
  onlyTextSamples?: boolean;
  initialSamplesNumber?: number;
};

export interface StartTrainRequest {
  executionId: DataGenExecutionId;
  trainingModel: StartTrainModel;
  asrConfig: Record<string, string>;
  nlpConfig: Record<string, string>;
}

export interface StartTrainResponse {
  status: TrainStatus;
  executionId: TrainExecutionId;
  prefectMonitorUrl: string;
}

export interface StartDataGenerationRequest {
  flowId: FlowId;
  flowVersion: Version;
  tenantId: string;
  sampleExecutionId?: string;
  dataGenSettings: DataGenSettings;
}

export interface GetSamplesRequest {
  executionId: DataGenExecutionId;
}

export interface StartDataGenerationResponse {
  status: DataGenerationStatus;
  executionId: DataGenExecutionId;
  prefectMonitorUrl: string;
}

export interface GetSamplesResponse {
  textSample: string;
  voiceSample: string;
}

export interface AbortDataGenerationRequest {
  executionId: DataGenExecutionId;
}
