import {
  GetDataGenerationQuery,
  GetDataGenerationQueryVariables,
  ListDataGenerationsQuery,
  ListDataGenerationsQueryVariables,
  ModelType,
} from '@ai-platform/common-types';
import {
  GetLatestDataSourceResponse,
  GetSamplesResponse,
  TextDataSourceSample,
  VoiceDataSourceSample,
} from '@ai-platform/data-generation-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import {
  AbortDataGenerationRequest,
  GraphQLResponse,
  ListDataGenerationsInput,
  StartDataGenerationRequest,
  StartDataGenerationResponse,
} from '../../aiPlatformBackendTypes';
import { dataSourceFromDataGeneration, dataSourceFromApi } from '../aiDataSources.adapters';
import { AiDataSource } from '../aiDataSources.types';
import { getDataGenerationQuery, listDataGenerationsQuery } from './aiDataSources.gql';

export const POLLING_INTERVAL = 5000;

export const aiDataSourcesApi = {
  getAiDataSource: async (executionId: string): Promise<AiDataSource | undefined> => {
    const query = getDataGenerationQuery;
    const variables: GetDataGenerationQueryVariables = {
      executionId,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<GetDataGenerationQuery>>(
      `${config.aipApiHost}/appsync/query`,
      {
        query,
        variables,
      },
      { fallback: { data: {} } },
    );
    return dataSourceFromApi(data.getDataGeneration) ?? undefined;
  },

  getAiDataSources: async (listDataGenerationsInput: ListDataGenerationsInput): Promise<AiDataSource[] | undefined> => {
    const query = listDataGenerationsQuery;
    const variables: ListDataGenerationsQueryVariables = {
      listDataGenerationsInput,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<ListDataGenerationsQuery>>(
      `${config.aipApiHost}/appsync/query`,
      {
        query,
        variables,
      },
      { fallback: { data: {} } },
    );

    return data?.listDataGenerations?.dataGenerations
      ?.map(dataSourceFromApi)
      .filter((dataSource): dataSource is AiDataSource => dataSource !== null);
  },

  getLatestAiDataSourcesByType: async (
    listDataGenerationsInput: ListDataGenerationsInput,
    dataSourceType?: ModelType,
  ): Promise<AiDataSource[] | undefined> => {
    const request = dataSourceType
      ? { listDataGenerationsInput: { ...listDataGenerationsInput, dataSourceType } }
      : { listDataGenerationsInput };
    const url = `${config.aipApiHost}/data-generation/get-latest-data-generation`;
    const { data } = await hermes.post<GetLatestDataSourceResponse>(url, request);
    return data?.dataGeneration
      .map(dataSourceFromDataGeneration)
      .filter((dataSource): dataSource is AiDataSource => dataSource !== null);
  },

  getDataSourceSamples: async (
    executionId: string,
    modelType: ModelType,
  ): Promise<TextDataSourceSample[] | VoiceDataSourceSample[] | undefined> => {
    const url = `${config.aipApiHost}/data-generation/get-samples?modelType=${modelType}&executionId=${executionId}`;
    const { data } = await hermes.get<GetSamplesResponse>(url);
    return data?.samples;
  },

  subscribeToAiDataSource: (id: string, callback: (aiModel: AiDataSource) => void) =>
    setInterval(async () => {
      const dataSource = await aiDataSourcesApi.getAiDataSource(id);
      if (dataSource) callback(dataSource);
    }, POLLING_INTERVAL),

  startDataSourceGeneration: async (input: StartDataGenerationRequest): Promise<StartDataGenerationResponse> => {
    const url = `${config.aipApiHost}/data-generation/start`;
    const { data } = await hermes.post<StartDataGenerationResponse>(url, input);
    return data;
  },
  abortGeneration: async (input: AbortDataGenerationRequest): Promise<boolean> => {
    const url = `${config.aipApiHost}/data-generation/abort`;
    const { data } = await hermes.post(url, input);
    // Successful response is empty
    return !data;
  },
};
