import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PolicyRouteParams } from 'routes/routes.config';
import { UpdatePolicyPayload, useUserMgmtStore } from 'stores/userMgmt';
import { useGroupArray, useUserArray, useUserMinifier } from 'stores/userMgmt/userMgmt.selectors';
import {
  EntityTableActions,
  EntityTableEmptyState,
  actionsTestIds,
  emptyStateTestIds,
  rowActionsTestIds,
} from './components';
import { columns, testIds as columnsTestIds } from './EntityTable.columns';
import { policyTableDefs } from './EntityTable.defs';
import classes from './EntityTable.module.css';
import { EntityTableState } from './EntityTable.types';

export const testIds = {
  getRowTestId: (id: string) => `entity-table-row-${id}`,
  table: 'entity-table',
  columns: columnsTestIds,
  emptyState: emptyStateTestIds,
  actions: actionsTestIds,
  rowActions: rowActionsTestIds,
};

export const EntityTable = () => {
  const { customerId, policyId } = useParams() as PolicyRouteParams;
  const [params, setParams] = useSearchParams();
  const { users, groups, policies, upsertPolicy } = useUserMgmtStore(['users', 'policies', 'groups', 'upsertPolicy']);
  const search = params.get('search') || '';
  const policy = policies[policyId];
  const usersArray = useUserArray();
  const groupsArray = useGroupArray();
  const getMiniUsers = useUserMinifier();
  const data = useMemo(
    () => [...policy.users.map((user) => users[user.userId]), ...policy.groups.map((group) => groups[group.id])],
    [policy, users],
  );

  const onSearch = (value: string) => {
    setParams((prev) => {
      prev.set('search', value);
      return prev;
    });
  };

  const onRemove: EntityTableState['onRemove'] = useCallback(
    async (removedPolicyId: string, userId: string) => {
      const miniUsers = getMiniUsers(policy.users.filter((user) => user.userId !== userId).map((user) => user.userId));
      const payload: UpdatePolicyPayload = { ...policy, users: miniUsers };
      const response = await upsertPolicy(customerId, { action: 'update', policyId: removedPolicyId, payload });
      return Boolean(response);
    },
    [customerId, policy],
  );

  const onChangeEntities: EntityTableState['onChangeEntities'] = useCallback(
    async (userIds, groupIds) => {
      const selectedMiniUsers = getMiniUsers(userIds);
      const selectedGroups = groupIds.map((groupId) => groups[groupId]);
      const payload: UpdatePolicyPayload = { ...policy, users: selectedMiniUsers, groups: selectedGroups };
      const response = await upsertPolicy(customerId, { action: 'update', policyId, payload });
      return Boolean(response);
    },
    [customerId, policies],
  );

  const table = useMantineReactTable({
    data,
    columns,
    ...policyTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: classes.row,
      'data-testid': testIds.getRowTestId(row.id),
    }),
    renderEmptyRowsFallback: (props) => <EntityTableEmptyState {...props} />,
    renderTopToolbar: (props) => <EntityTableActions {...props} />,
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    state: {
      globalFilter: search,
      users: usersArray,
      groups: groupsArray,
      policy,
      onChangeEntities,
      onSearch,
      onRemove,
    } as EntityTableState,
  });

  return <MantineReactTable table={table} data-testid={testIds.table} />;
};
